:root {
	--color-bg: #18191a;
	--color-text: #f5f6f7;

	--color-primary: #0f715e;
	--color-primary-dark: #0b5446;

	--color-secondary: #202c33;
	--color-secondary-dark: #15192c;
}

.App {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: var(--color-bg);
	color: var(--color-text);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
