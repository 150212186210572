.footer{
    font-size: .75rem;
    display: flex;;
    justify-content: center;
    flex-wrap: wrap;;
    border-top: solid 1px grey;// var(--color-secondary-dark);
    padding-top: 1rem;
    margin-bottom: 1rem;
    .group{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    a{
        
        // display: block;
        color: var(--color-text);
        color: grey;
        margin: 0 3rem;
        text-decoration: none;
        // margin: 1rem;
    }
}


@media screen and (min-width: 1000px){
    .footer{
        .group{
            width: 50%;
        }
    }
}