.button{
    padding: .75rem;
    border-radius: .75rem;;
    min-width: 5rem;;
    cursor: pointer;
    font-size: 1.25rem;
    border: none;
    font-weight: 500;;
    transition: all .3s;
    &:hover, &:focus{
        background: var(--color-primary);
        font-weight: 700;
    }
    &:disabled{
        background: var(--color-secondary);
        cursor: not-allowed;
    }
} 